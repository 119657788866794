<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef" :model="searchForm">
        <a-form-model-item prop="stock_name" label="发放批次">
          <a-input style="width:300px" allowClear placeholder="请输入批次名" v-model="searchForm.stock_name" />
        </a-form-model-item>
        <a-form-model-item prop="regional_name" label="城市商户号">
          <a-input style="width:300px" allowClear placeholder="请输入城市" v-model="searchForm.regional_name" />
        </a-form-model-item>
        <!-- <a-form-model-item 
            prop="financial_account_id" label="财务账户">
          <a-select style="width:280px;" placeholder="请选择账户"
               v-model="searchForm.financial_account_id"
               @change="changeAccount">
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="(item, index) in financeAccountList"
                 :value="item.id"
                 :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item>
          <a-button class="mr-2" @click="handlerResetForm">重置</a-button>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <base-table class="mt-4"
      :columnsData="columns"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #is_user_signature="{text}">
        {{ text==2 ? "需要" : "" }}
      </template>
      <template #operation="{record}">
        <a-button class="p-0" type="link" @click="handlerCardList(record)">查看</a-button>
        <!-- <a-button class="p-0 ml-3" type="link" @click="handlerCardExport(record)">导出</a-button> -->
      </template>
    </base-table>


    <!-- 抽屉展示批次详情 -->
    <a-drawer title=""
              :get-container="false"
              :wrap-style="{ position: 'absolute' }"
              width="73vw"
              placement="left"
              destroyOnClose
              :visible="isShowDetail"
              @close="isShowDetail = false">
      <div class="flex justify-between items-center" style="height:40px;">
        <h4 class="mb-0 ml-4">{{detailRow.stock_name}}</h4>
        <a-button class="p-0 mr-20" type="link" 
          :disabled="isExportLoading" @click="handlerCardExport2">导出全部</a-button>
      </div>
      <detailList :stockId="detailRow.stock_id"
                  :stockName="detailRow.stock_name"
                   v-if="isShowDetail"></detailList>
    </a-drawer>
  </div>
</template>

<script>

// import {
//   getList,
// } from "@/api/system/account.js"
import { 
  getPurchaseList,
  exportPurchaseCardsList,
  getPurchaseCardsList
} from "@/api/activity/storevalue-card.js"

import detailList from "./group-purchase-detail.vue"
import table2excel from 'js-table2excel'
import CONFIG from "@/utils/config.js"

export default {
  components: {
    detailList,
  },
  data() {
    return {
      searchForm: {
        stock_name: '',
        financial_account_id: '',
        page: 1,
        page_count: 20,
      },
      total: 0,

      financeAccountList: [],

      tableData: [],
      columns: [
        {
          title: "批次ID",
          dataIndex: "stock_id",
          align: "center",
          width: 70,
        },
        {
          title: "批次名",
          align: "center",
          dataIndex: "stock_name",
          width: "30%",
        },
        {
          title: "城市商户号",
          align: "center",
          dataIndex: "regional_name",
          width: "20%",
        },
        {
          title: "备注信息",
          align: "center",
          dataIndex: "remarks",
          width: "40%",
        },
        {
          title: "签名领取",
          align: "center",
          dataIndex: "is_user_signature",
          width: 80,slots: {
            customRender: "is_user_signature",
          },
        },
        {
          title: "总数",
          align: "center",
          dataIndex: "card_count",
          width: 80,
        },
        {
          title: "已领取",
          align: "center",
          dataIndex: "receive_count",
          width: 80,
        },
        // {
        //   title: "财务账户",
        //   align: "center",
        //   dataIndex: "financial_account_name",
        // },
        {
          title: "添加时间",
          align: "center",
          dataIndex: "add_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: 80,
          align: "center",
          slots: {
            customRender: "operation",
          },
        },
      ],

      isShowDetail: false,
      detailRow: {},
      isExportLoading: false,
      loadingHideCallback: false,
    }
  },
  mounted() {
    // getList().then(r=>{
    //   if(r.code == 0){
    //     this.financeAccountList = r.data.list
    //   }
    // })
    // this.searchForm.financial_account_id = data.list[0].id

    this.initData()
  },
  methods: {
    async initData() {
      const { code, data } = await getPurchaseList(this.searchForm)
      if (code == 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    handlerCardList(row) {
      this.detailRow = row
      this.isShowDetail = true
    },
    handlerCardExport(row) {
      exportPurchaseCardsList({
        stock_id: row.stock_id
      })
    },
    async handlerCardExport2(){
      this.showExportLoading()

      const headerColumn = [
        {
          title: "手机号",
          key: "phone",
          type: "text",
        },
        {
          title: "加密卡号",
          key: "encryption_card_no",
          type: "text",
        },
        {
          title: "卡号",
          key: "card_no",
          type: "text",
        },
        {
          title: "卡面额",
          key: "denomination",
          type: "text",
        },
        {
          title: "领取状态",
          key: "receive_status_show",
          type: "text",
        },
        {
          title: "领取时间",
          key: "receive_time",
          type: "text",
        },
        {
          title: "签名",
          key: "user_signature_url",
          type: "image",
          width: 153,
          height: 84,
        },
      ]
      
      let list = await this.loadExportData()
      list.map(el=>{
        el.receive_status_show = el.receive_status==1?"已领取":"未领取"
        el.user_signature_url = el.user_signature_path ? (CONFIG.HOST_IMG_P2 + el.user_signature_path) : null
      })
      table2excel(headerColumn, list, "团购卡明细导出_"+this.detailRow.stock_name)

      this.hideExportLoading()
    },
    async loadExportData(page, list){
      list = list || []
      page = page || 1
      const params = {
        stock_id: this.detailRow.stock_id,
        page_count: 400,
        page: page,
      }

      const { data, code } = await getPurchaseCardsList(params)
      if (code === 0) {
        list = list.concat(data.list)
        if(list.length == data.total_count){
          return list
        }
        if(page >= 5){ // 保护机制：不超过2000条
          return list
        }
        return this.loadExportData(page+1, list)
      }else{
        return list
      }
    },

    showExportLoading(){
      this.hideExportLoading()
      this.isExportLoading = true
      this.loadingHideCallback = this.$message.loading("正在生成文件...", 0)
    },
    hideExportLoading(){
      if(this.loadingHideCallback){
        this.loadingHideCallback()
        this.loadingHideCallback = null
      }
      this.isExportLoading = false
    },

    // 搜索
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    // 重置
    handlerResetForm() {
      this.searchForm.stock_name = ""
      this.searchForm.page = 1
      this.searchForm.financial_account_id = ""
      this.$refs.searchRef.resetFields()
      this.initData()
    },

    changeAccount(){
      this.handlerSearch()
    },

  },
}
</script>

<style lang="less" scoped>
</style>