<template>
  <div>
    <base-table
      :addHeight="-30"
      :columnsData="columns"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #receive_status="{ text }">
        <!-- 0-未领取，1-已领取 -->
        <a-tag v-if="text==1" class="mr-0" color="green">已领取</a-tag>
        <a-tag v-else class="mr-0" color="blue">未领取</a-tag>
      </template>
      <template #user_signature_path="{ record }">
        <template v-if="record.receive_status == 1">
          <a-button v-if="record.user_signature_path" 
            type="link" class="p-0" @click="previewSign(record)">查看</a-button>
          <span v-else>——</span>
        </template>
      </template>
    </base-table>
    
    <a-modal class="preview-class" title="签字"
      width="500px"
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false">
      <div>
        <img style="width: 100%" :src="previewSignImg" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import {  
  getPurchaseCardsList
} from "@/api/activity/storevalue-card.js"
import CONFIG from "@/utils/config.js"

export default {
  components: { },
  props: {
    stockId: {
      type: Number,
      default: 0,
    },
    stockName: {
      type: String,
      default: "",
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      searchForm: {
        stock_id: '',
        financial_account_id: '',
        phone: '',
        page: 1,
        page_count: 20,
      },
      total: 0,
      
      tableData: [],
      columns: [
        {
          title: "手机号",
          align: "center",
          dataIndex: "phone",
          width: 130,
        },
        {
          title: "加密卡号",
          align: "center",
          dataIndex: "encryption_card_no",
        },
        {
          title: "卡号",
          dataIndex: "card_no",
          align: "center",
          // width: "30%",
        },
        {
          title: "卡面额",
          dataIndex: "denomination",
          align: "center",
          width: 80,
        },
        {
          title: "领取状态",
          align: "center",
          dataIndex: "receive_status",
          slots: {
            customRender: "receive_status",
          },
          width: 80,
        },
        {
          title: "领取时间",
          align: "center",
          dataIndex: "receive_time",
          width: 190,
        },
        {
          title: "签名",
          align: "center",
          dataIndex: "user_signature_path",
          width: 80,
          slots: {
            customRender: "user_signature_path",
          },
        },
      ],

      previewSignImg: "",
      previewVisible: false,
    }
  },
  watch:{
    num(){
      this.initData()
    }
  },
  created() {
    // this.requestID = this.$route.params.id
    this.searchForm.stock_id = this.stockId
    this.initData()
  },
  methods: {

    async initData() {
      const { data, code } = await getPurchaseCardsList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },

    previewSign(record){
      this.previewSignImg = CONFIG.HOST_IMG_P2 + record.user_signature_path
      this.previewVisible = true
    },
  },
}
</script>

<style lang="less" scoped>
.order-detail-container {
  // margin: 20px;
  padding: 20px 20px 50px 20px;
  background: #ffffff;
}
.shop-table-class {
  // margin-left: -10px;
}
.order-detail-title {
  width: 70px;
  text-align: right;
  display: inline-block;
}

.bottom-btn{
  padding: 0;
  margin-right: 20px;
}
</style>
<style>
.ant-drawer-body{
  padding: 0;
}
</style>
